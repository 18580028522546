import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from "../../components/Layout";
import CTASection from "../../components/CTASection";
import Author from "../../components/Author";
import { Helmet } from "react-helmet";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = props => <Layout {...props} cta title="Why Bedrock uses urql as its GraphQL client" description="Urql is an alternative to Apollo Client that Bedrock uses as its GraphQL client. It's fantastic developer experience, extensibility, small bundle size and active maintainance make it my favorite choice!" image="https://bedrock.mxstbr.com/urql-meta.png" />;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <div align="center" style={{
      marginTop: `2em`
    }}>
      <h1>{`Why Bedrock uses urql as its GraphQL client`}</h1>
      <Author mdxType="Author" />
    </div>
    <p><a parentName="p" {...{
        "href": "https://github.com/formidablelabs/urql"
      }}><strong parentName="a">{`urql`}</strong></a>{` is a GraphQL client created by the folks at Formidable Labs. I have used it for all of my projects over the past two years, and have had a fantastic experience with it. There are four main reasons why I prefer it over the alternatives:`}</p>
    <ul>
      <li parentName="ul"><strong parentName="li">{`Developer experience`}</strong>{`: The urql developers have fantastic taste in developer experience. The APIs are well considered, they let you accomplish everything you need to while being intuitive and easy to understand and learn.`}</li>
      <li parentName="ul"><strong parentName="li">{`Extensibility`}</strong>{`: Yet, even with those carefully designed APIs, urql doesn't restrict you. It ships with many optional "exchanges" (i.e. plugins) to add offline support, retrying, file uploads, and many other things you might want for your app, and you can write your own whenever you want.`}</li>
      <li parentName="ul"><strong parentName="li">{`Small bundle size`}</strong>{`: Adding urql and its normalized "graphcache" to your app increases your bundle size by 22kB. Other GraphQL clients increase your bundle size by 43kB min + gzip`}<sup><a parentName="li" {...{
            "href": "https://gist.github.com/mxstbr/d2151f35546b1589902cf86517ee52ee"
          }}>{`1`}</a></sup>{` — almost double the cost for the same feature set!`}</li>
      <li parentName="ul"><strong parentName="li">{`Ahead of the curve`}</strong>{`: urql has had hooks and experimental suspense support since the React team announced them! The urql folks are very actively improving urql and staying ahead of the curve with new features added to React.`}</li>
    </ul>
    <p>{`All in all, I would highly recommend using urql for your next project, which is why Bedrock comes pre-configured with urql out of the box. However, it lets you switch to a different GraphQL client if you want to, of course!`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      